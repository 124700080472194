'use strict';
// 168198
$(document).ready(function () {
    // How It Works
    var tabLinks = $('.c-module__tab').find('.c-module__tab__link a');
    $.each(tabLinks, function (i, e) {
        $(e).on('click', function (event) {
            event.preventDefault();

            $('.c-module__tab__link').removeClass('active');
            $('.c-module__tab__content').removeClass('active');
            $('.c-module__tab__wrapper .active').removeClass('active');

            $(this).parent().addClass('active');
            var tabContent = $(this).attr('href');
            $(tabContent).addClass('active');
        });
    });

    var generateHiwCards = function () {
        $('.how-it-works .c-cards').slick({
            dots: true,
            arrows: false,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 3,
            variableWidth: true
        });
    };

    $(window).resize(function () {
        (function (rx) {
            var hiwCards = $('.how-it-works .c-cards');
            if (rx === true) {
                if (hiwCards.hasClass('slick-initialized') === false) {
                    generateHiwCards();
                }
            } else if (rx === false) {
                if (hiwCards.hasClass('slick-initialized') === true) {
                    $('.how-it-works .c-cards').slick('unslick');
                }
            }
        }(window.matchMedia("(max-width: 768px)").matches));
    });

    if (window.matchMedia("(max-width: 768px)").matches) {
        generateHiwCards();
    }

    // What Our Students Are Saying
    $('.c-review-card__slides').slick({
        dots: true,
        arrows: true,
        centerMode: true,
        slidesToShow: 1,
        variableWidth: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: true,
                    slidesToShow: 1,
                    variableWidth: true,
                    infinite: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    centerMode: true,
                    slidesToShow: 1,
                    variableWidth: true,
                    infinite: true
                }
            }
        ]
    });

    $(document).on('afterChange', '.c-review-card__slides', function (slick) {
        $('.slick-slide:not(.slick-active) .c-review-card__quote').attr('tabindex', '-1');
        $('.slick-slide:not(.slick-active) .c-review-card__name').attr('tabindex', '-1');
        $('.slick-slide:not(.slick-active) .c-review-card__subject').attr('tabindex', '-1');
        $(this).find('.slick-active .c-review-card__quote').attr('tabindex', '0');
        $(this).find('.slick-active .c-review-card__name').attr('tabindex', '0');
        $(this).find('.slick-active .c-review-card__subject').attr('tabindex', '0');
        $(this).find('.slick-current').find('.c-review-card__quote').focus();
    });

    if ($('.header-top-banner .content').children().length === 0) {
        $('.header-top-banner').addClass('d-none');
    } else {
        $('.header-top-banner').removeClass('d-none');
    }
    // Hero Banner
    $('.hero-carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        pauseOnDotsHover: false,
        autoplaySpeed: 8000,
        dots: true,
        arrows: true,
        infinite: true
    });

    $('.pause-resume-slider').on('click', function () {
        if ($(this).hasClass('play')) {
            $(this).removeClass('play').addClass('pause');
            $(this).attr('aria-label', 'Pause Slider');
            $(this).siblings('span').text('Pause Slider');
            $('.hero-carousel').slick('slickSetOption', 'autoplay', true).slick('slickPlay');
        } else {
            $(this).removeClass('pause').addClass('play');
            $(this).attr('aria-label', 'Play Slider');
            $(this).siblings('span').text('Play Slider');
            $('.hero-carousel').slick('slickSetOption', 'autoplay', false).slick('slickPause');
        }
    });
    // 156512
    $('.hash-link-rfi').on('click', function () {
        var sghn = $('.st-global-header__nav').height();
        $('html, body').animate({
            scrollTop: $('#rfi').offset().top - sghn
        }, 1000, 'swing');
    });
});

$(window).on('load', function () {
    $('.slick-arrow').each(function () {
        $(this).attr('title', $(this).attr('aria-label'));
    });
    $('.c-review-card__slides .slick-slide').each(function () {
        $(this).find('.c-review-card__quote').attr('aria-label', $(this).find('.c-review-card__quote').text());
        $(this).find('.c-review-card__name').attr('aria-label', $(this).find('.c-review-card__name').text());
        $(this).find('.c-review-card__subject').attr('aria-label', $(this).find('.c-review-card__subject').text());
    });
    // 72864, 165827, 156512
});
